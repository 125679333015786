<template>
    <div>
        <div class="container header-fixed">
            <div class="section">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="d-flex align-items-center pb-3 border-bottom mb-4">
                            <a href="ppdb-formulir-saya.html" class="mr-3 text-dark">
                                <span class="fe fe-arrow-left text-md"></span>
                            </a>
                            <h1 class="m-0">Formulir New Generation X</h1>
                        </div>
                        <div class="d-block mb-5">
                            <div class="d-flex align-items-center">
                                <div class="icon-circle icon-circle-primary-light mr-3">
                                    <span class="fe fe-user"></span>
                                </div>
                                <div>
                                    <div class="d-block font-weight-bold">Sekolah Tinggi Ilmu Fisika, Bandung</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-8">
                                <div class="d-block pr-lg-5 pr-md-4 pr-0">
                                    <div class="d-block text-sm mb-3 text-muted">INFORMASI</div>
                                    <div class="d-block">
                                        <p>Informasi pelaksanaan tes seleksi Perolehan Formulir (PPDB) SMAIT
                                            Ihsanul Fikri Mungkid TP. 2020/2021 berikut ini kami sampaikan beberapa hal
                                            yang perlu disiapkan terkait pelaksaan tes masuk :</p>
                                        <p>TES PESERTA BARU TP. 2020/2021 DITUTUP RABU, 8 JANURARI 2020 PUKUL
                                            24.00 WIB</p>
                                        <p>KEPADA SEMUA PESERTA WAJIB MENYELESAIKAN PENDAFTARAN SAMPAI TAHAP FINALISASI,
                                            UNDUH KARTU TES, FORMULIR PENDAFTARAN, DAN SURAT PERNYATAAN BERMATERAI</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-block text-sm mb-3 text-muted">DAFTAR TAUTAN</div>
                                <div class="d-block">
                                    <a href="#" class="d-flex w-100 mb-3 text-dark">
                                        <div
                                            class="d-flex align-items-center justify-content-between flex-grow-1 pb-2 border-bottom">
                                            <div class="font-weight-bold mr-3">
                                                Berkas Pendaftaran yang Harus Dibawa
                                            </div>
                                            <div class="fe fe-chevron-right"></div>
                                        </div>
                                    </a>
                                    <a href="#" class="d-flex w-100 mb-3 text-dark">
                                        <div
                                            class="d-flex align-items-center justify-content-between flex-grow-1 pb-2 border-bottom">
                                            <div class="font-weight-bold mr-3">
                                                Susunan Acara Tes PPDB 2020/2021
                                            </div>
                                            <div class="fe fe-chevron-right"></div>
                                        </div>
                                    </a>
                                    <a href="#" class="d-flex w-100 mb-3 text-dark">
                                        <div
                                            class="d-flex align-items-center justify-content-between flex-grow-1 pb-2 border-bottom">
                                            <div class="font-weight-bold mr-3">
                                                Materi Tes PPDB
                                            </div>
                                            <div class="fe fe-chevron-right"></div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src

    export default {
        name: 'Formulir',
        components: {
            
        }
    }
</script>